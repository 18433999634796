<template>
	<div class="maintain">
		<Header></Header>
		<div class="wrapper">
			<div class="search shadow" style="margin: 20px 0 0;">
				<span class="title">筛选：</span>
				<el-select v-model="fleetValue" placeholder="请选择车队">
					<el-option v-for="item in fleetOptions" :key="item.id" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
				<el-input class="search-input" v-model="CarIdValue" placeholder="请输入车牌号"></el-input>
				<el-button type="primary">搜索</el-button>
			</div>
			<div class="main-title">保养列表</div>
			<checkList :checkList="checkList"></checkList>
			<el-pagination background layout="prev, pager, next" :total="1000">
			</el-pagination>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	import checkList from "../../components/checkList.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			checkList
		},
		data() {
			return {
				checkList: [{
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 1
				}, {
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 2
				}, {
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 2
				},{
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 1
				}, {
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 2
				}, {
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 2
				}, {
					fleet: "宝达车队",
					carId: "鲁C SH001",
					time: "2021-11-19",
					type: 2
				}]
			}
		},
		methods: {
			checkClick(n) {
				if (n == 1) {
					this.$router.push("/firstForm")
				} else {
					this.$router.push("/generalForm")
				}
			},
			GetFleetList() {
				var _this = this;
				_this.$axios({
					methods: "get",
					url: "https://www.fastmock.site/mock/25e5edeb845d96a32221b0c2f597b57e/practise/fleetList"
				}).then(res => {
					// console.log(res.data);
					_this.CarIdOptions = res.data;
				})
			}
		},
		created() {
			// this.GetFleetList()
		}
	}
</script>
<style lang="scss">
	body {
		background: $main-bg-color;
	}
</style>
